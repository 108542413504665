import React from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import events from "./MonthlyEvents";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "../App.css"

const localizer = momentLocalizer(moment);

 export default class MonthlyEventCalendar extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          width: "80vh",
          height: "80vh",
          events: events
        };
    }

  

 
render() {
  let CURRENT_DATE = moment().toDate();
  const formats = {
   selectRangeFormat : () => {
     return "8:00am — 9:00pm"
   }
  };
    return (
       <div className="App">

         <div>
         <Calendar
          localizer={localizer}
          defaultDate={CURRENT_DATE}
          defaultView={'month'}
          formats={formats}
          timeslots={6}
          events={this.state.events}
          style={{ height:this.state.height, width: this.state.width ,
          display: "inline-block",
          placeItems: "center",
                 }}
        />

         </div>
       </div>
    );

  }

}

/*
{
              start: moment().toDate(),
              end: moment()
                .add(1, "days")
                .toDate(),
              title: "Some title"
            },
             events: [
            {
              start: new Date('March 17 2021 19:00 PST'),
              end: new Date('March 17 2021 21:00 PST'),
              title: "Raja Rajewari Archana @ 6:00 pm",
              desc: 'Archana'
            }
          ]
            */