import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Home from './pages/Home';
import Maharudram from './pages/Maharudram';
import SitaKalyanam from './pages/SitaKalyanam';
import AvaniAvittam from './pages/AvaniAvittam';

ReactDOM.render(
  <React.StrictMode>
  <Router >
    <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/home" element={<Home />}/>
        <Route path="/maharudram" exact element={ <Maharudram />} />
        <Route path="/sitakalyanam" element={<SitaKalyanam />}  exact/>
        <Route path="/avaniavittam" element={<AvaniAvittam />}  exact/>
    </Routes>
   </Router>
    </React.StrictMode> ,
   document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
