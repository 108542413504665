import React from 'react';

import Header from "./Header";
import Card from 'react-bootstrap/Card';
import {Container,Button,Row,Col} from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';

export default class SitaKalyanam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        index: 0
    };
}
    render() {
      return(
        <div className="App">
        <Header />

        <Card>
           <Card.Header as="h3" style={{backgroundColor : '#75C0E0'}}>Sree Sita Rama Kalyanam 2024</Card.Header>
           <Card.Body style={{backgroundColor : '#ACD7EB'}}>
            <Card.Title> Sree Sita Rama Kalyanam </Card.Title>
            <Card.Text>
                Sri Raja Rajeswari temple cordially invites you and your family to attend Sita Rama Kalyanam performed by Gnanananda Seva Samajam
                on April 16 2024 between 4:30 pm - 8:30 pm
            </Card.Text>
      </Card.Body>
    </Card>
    <hr></hr>
        <p></p>
        <p></p>
        <div class="content">
        <Container fluid>
        <hr></hr>
        <Button style={{ width: '10rem', justifyContent: 'left',fontWeight: 'bold',backgroundColor:'#facf82' }} variant="outline-danger" href="https://paybee.io/@srrt@32">Sita Kalyanam Donation</Button>
        <hr></hr>

        <Row style={{width: '100%',marginLeft: 'auto', marginRight: 'auto'}}>
          <Col>
          <Table bordered striped size="sm" style={{width: '98%', textAlign: 'left', marginLeft: 'auto', marginRight: 'auto'}}>
  <thead>
    <tr>
      <th colspan="4" style={{ textAlign: 'center', backgroundColor: '#FFBD33' }}>Sponsorship Oppurtunities</th>
      
    </tr>
    <tr style={{ backgroundColor: '#facf82'} }>
      <th>Details</th>
      <th>Amount</th>
    </tr>
  </thead>
  <tbody>
   
   
    <tr>
      <td>Grand Spnsor</td>
      <td>$2,501 or above</td>
    </tr>
    <tr >
      <td>Flowers</td>
      <td>$1001 or above</td>
    </tr>
    <tr>
      <td>Mangalya Dharanam</td>
      <td>$351</td>
    </tr>
    <tr>
      <td>Kanya Dhanam</td>
      <td>$251</td>
    </tr>
    <tr>
      <td>Vasthram</td>
      <td>2101</td>
    </tr>
    <tr>
      <td>Annadhanam </td>
      <td>$251</td>
    </tr>
    <tr>
      <td>Fruits </td>
      <td>$101</td>
    </tr>
   
  </tbody>
</Table>

          </Col>

        </Row>

        

<hr></hr>
       
  </Container>
      </div>
      </div>

      );
    }
}