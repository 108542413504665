import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';
import apikey from './apikey';


export default class PriestServiceRegistration extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            show: true,
            firstName: "",
            lastName: "",
            email: "",
            address: "",
            phonenumber: "",
            attempleplace: false,
            atresidence: false,
            poojaservice: "",
            poojadate: ""
        };
        this.handlePriestFormSubmit = this.handlePriestFormSubmit.bind(this);
        this.handlePSRClose = this.handlePSRClose.bind(this);
    }

    changeState() {
      console.log(" In change State");
      this.props.handlePSRState();
    };

    handlePSRClose(event) {
      console.debug(" in handle PSRClose");
      this.setState({validated : true});
      this.setState({show: false});
      this.changeState();
    };
  


    handlePriestFormSubmit(event) {
        const form = event.currentTarget;
        let pl = "at temple";
        if (this.state.atresidence) {
            pl = "at my residence"
        }
        var templateParams = {
            name: this.state.firstName + " " + this.state.lastName,
            address: this.state.address,
            phonenumber: this.state.phonenumber,
            email: this.state.email,
            poojadate: this.state.poojadate,
            poojaservice: this.state.poojaservice,
            place: pl
        };
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else {
            event.preventDefault();
            emailjs.send('service_gmail', apikey.TEMPLATE_ID, templateParams, apikey.USER_ID)
           .then(result => {
                   alert('Message Sent, Priest will get back to you shortly', result.text);
                   this.setState({show: false})
            },
            error => {
               alert( 'An error occured, Plese try again later',error.text)
            })
        }
        this.handlePSRClose();
      };

    
    render() {
        return (
            <>
              <Modal
                show={this.state.show}
                onHide={this.handlePSRClose}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                  Priest Service Registration
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {this.modalbox()}
                </Modal.Body>
              </Modal>
            </>
          );
    }

    
    modalbox()
    {
        return (
          <div>
        <Form align="left" noValidate validated={this.state.validated} onSubmit={this.handlePriestFormSubmit}>
         <Form.Row>
           <Form.Group as={Row} controlId="validationCustomFirstName">
             <Form.Label column sm="2" >First name</Form.Label>
             <Col sm="4">
             <Form.Control
               required
               type="text"
               placeholder="First name"
               defaultValue=""
               onChange={(e) => {this.setState({firstName: e.target.value})}}
             />
             </Col>
             <Form.Label column sm="2">Last name</Form.Label>
             <Col sm="4">
             <Form.Control
               required
               type="text"
               placeholder="Last name"
               defaultValue=""
               onChange = {(e) => {this.setState({lastName: e.target.value})}}
             />
             </Col>
           </Form.Group>
           </Form.Row>
           <Form.Row>
           <Form.Group as={Row}  controlId="validationCustomEmail">
             <Form.Label column sm="2">Email Address</Form.Label>
             <Col sm="10">
             <InputGroup hasValidation>
               <Form.Control
                 type="text"
                 placeholder="email"
                 aria-describedby="inputGroupPrepend"
                 required
                 onChange={(e) => {this.setState({email: e.target.value})}}
               />
             </InputGroup>
             </Col>
           </Form.Group>
         </Form.Row>
         <Form.Row>
           <Form.Group as={Row} md="6" controlId="validationCustom03">
             <Form.Label column sm="2">Phone Number</Form.Label>
             <Col sm="10">
             <Form.Control type="text" required   onChange={(e) => {this.setState({phonenumber: e.target.value})}}/>
             <Form.Control.Feedback type="invalid">
               Please provide phone number.
             </Form.Control.Feedback>
             </Col>
           </Form.Group>
         </Form.Row>
         <Form.Row>
           <Form.Group as={Row} controlId="validationCustom04">
             <Form.Label column sm="2">Address</Form.Label>
             <Col sm="10">
             <Form.Control type="text" required  onChange={(e) => {this.setState({address: e.target.value})}}/>
             <Form.Control.Feedback type="invalid">
               Please provide address.
             </Form.Control.Feedback>
             </Col>
           </Form.Group>
           </Form.Row>
           <Form.Row>
           <Form.Group>
           <Form.Check
             label="Priest Service in temple"
             checked = {this.state.attempleplace}
             onChange = {(e) => {this.setState({attempleplace: e.target.checked})}}
           />
         </Form.Group>
         </Form.Row>
         <Form.Row>
         <Form.Group>
           <Form.Check
             label="Priest Service at your place"
             checked = {this.state.atresidence}
             onChange = {(e) => {this.setState({atresidence: e.target.checked})}}
           />
         </Form.Group>
         </Form.Row>
         <Form.Row>
           <Form.Group as={Row} controlId="validationCustom10">
             <Form.Label column sm="2">Pooja Date</Form.Label>
             <Col sm="10">
             <Form.Control type="date" required  onChange={(e) => {this.setState({poojadate: e.target.value})}}/>
             <Form.Control.Feedback type="invalid">
               Please enter date.
             </Form.Control.Feedback>
             </Col>
           </Form.Group>
           </Form.Row>
         <Form.Row>
           <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
              <Form.Label column sm="6" >Which Priest Service do you need? </Form.Label>
              <Col>
              <Form.Control required as="textarea" rows={3}  onChange={(e) => {this.setState({poojaservice: e.target.value})}}/>
              <Form.Control.Feedback type="invalid">
               Please specify which priest service you need.
             </Form.Control.Feedback>
              </Col>
         </Form.Group>
         </Form.Row>
         <Button type="submit">Submit form</Button>
       </Form>
   
          </div>
        );
      }

       }