
// https://dashboard.emailjs.com/
// username : srrtsanjose@gmail.com
// password : rajarajeswari

export default {
USER_ID: 'user_fdTuxUxwtOFUtFA6e3UBu',
TEMPLATE_ID: 'template_priest_service'
}

