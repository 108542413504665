import React from 'react';
import './App.css';
import Home from "./pages/Home";
import Header from "./pages/Header";

export default class App extends React.Component {
  
  render() { 
  return (
    <div className="App">
      <Header />
      <div class="content">
        <Home />
      </div>
    </div>
  );

  }
}

/*
 <Header />
 <div class="content">
        <Home />
      </div>

*/


