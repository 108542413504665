import React from 'react';
import {Container,Button,Row,Col} from "react-bootstrap";
import Header from "./Header";
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'

export default class AvaniAvittam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        index: 0
    };
}
    render() {
      return(
        <div className="App">
        <Header />
        <h2>Avani Avittam</h2>
        <hr></hr>
        <Button style={{ width: '10rem', justifyContent: 'center',fontWeight: 'bold',backgroundColor:'#facf82' }} variant="outline-danger" href="/assets/avaniavittam2023.pdf" download>Download Kamokarshi Japam document</Button>
        <hr></hr>
        <Card>
            <Card.Body>
            <Image  src="/assets/flyers/avaniavittam2023.jpg"  style= {{flex: 1,resizeMode: 'contain'}} ></Image>
            </Card.Body>

          </Card>
        
        </div>
      );
    }
}