import React from 'react';
import Header from "./Header";
import {Container,Button,Row,Col} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import {useNavigate} from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';

export default class Maharudram extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        index: 0
    };
  
   }
    render() {
     
      return(
        <div className="App">
        <Header />

        <Card>
           <Card.Header as="h3" style={{backgroundColor : '#75C0E0'}}>Maharudram 2024</Card.Header>
           <Card.Body style={{backgroundColor : '#ACD7EB'}}>
            <Card.Title>7th Annual Sri Maha Rudra Yajnam</Card.Title>
            <Card.Text>
                By the divine grace of Sri Raja Rajeswari Devi and the blessings of Sri Sankaracharyas,
                Sri Raja Rajeswari temple plans to conduct the 7th Annual Maha Rudra Yajnam this year including Lalitha Sahasranama Archana and Deepa Pooja from May 10 -12 2024.
                We request all Devotees to participate in this event, receive the blessings of Lord Shiva and Ambal and donate generously.

            </Card.Text>
      </Card.Body>
    </Card>
    <hr></hr>
        <p></p>
        <p></p>
        <div class="content">
        <Container fluid>
        <Row style={{width: '100%%',marginLeft: 'auto', marginRight: 'auto'}}>
          <Col>
            <Card style={{ width: '18rem', backgroundColor: '#ddeff7' }}>
             <Card.Body>
               <Card.Title> Ritwik Registration</Card.Title>
                <Card.Text>
                   Rudram chanting
                </Card.Text>
                <Button variant="primary" href="https://paybee.io/@srrt@34">Register</Button>
             </Card.Body>
            </Card>
           </Col>
          <Col>
            <Card style={{ width: '18rem', justifyContent: 'right',backgroundColor: '#ddeff7' }}>
             <Card.Body>
               <Card.Title> Deepa Pooja Registration</Card.Title>
               <Card.Text>
                  Lalitha Sahasranama Pooja By Suvasinis
                </Card.Text>
               <Button variant="primary" href="https://paybee.io/@srrt@37">Register</Button>
             </Card.Body>
            </Card>
        </Col>
        </Row>
        <hr></hr>
        <Button style={{ width: '10rem', justifyContent: 'left',fontWeight: 'bold',backgroundColor:'#facf82' }} variant="outline-danger" href="https://paybee.io/@srrt@36">Maharudram Donation</Button>
        <hr></hr>

        <Row style={{width: '100%',marginLeft: 'auto', marginRight: 'auto'}}>
          <Col>
          <Table bordered  size="sm" style={{width: '100%', textAlign: 'left', marginLeft: 'auto', marginRight: 'auto'}}>
            <thead>
               <tr >
                <th colspan="2" style={{ textAlign: 'center', backgroundColor: '#FFBD33' }}>Program</th>
                </tr>
           </thead>
           <tbody>
             <tr style={{backgroundColor: '#facf82', fontWeight: 'bold'}}>
               <td style={{width: '15%', textAlign: 'left'}}>May 10</td>
               <td>Friday</td>
             </tr>
            <tr>
              <td>9:00 am</td>
              <td>Ganapathi Homam, Navagraha Homam, Sarva Devatha Homam</td>
            </tr>
            <tr>
               <td>7:00 pm</td>
               <td>Sankalpam followed by Lalitha Sahasranama Archana and Deepa Pooja ( 100+ Suvasinis) </td>
             </tr>
            <tr style={{backgroundColor: '#facf82', fontWeight: 'bold'}}>
               <td>May 11</td>
               <td>Saturday</td>
             </tr>
             <tr>
                  <td>6:30 am</td>
                  <td>Maha Ganapati pooja,Maha Sankalpam, Mahanyasam, Kalasha Sthapana,Sri Rudram Chanting(by 121+ Ritwicks,Abhishekam, Maha Arati , Prasadam</td>
              </tr>
   
              <tr>
                 <td>6:00 pm</td>
                  <td>Rudra Karma Archana,Ghanapaatam, Ashtavadana Seva,Aarati and Prasadam</td>
              </tr>
  
              <tr style={{backgroundColor: '#facf82', fontWeight: 'bold'}}>
                  <td>May 12</td>
                  <td>Sunday</td>
               </tr>
               <tr>
                   <td>6:30 am</td>
                   <td>Maha Rudra Homam</td>
                </tr>
                <tr>
                   <td>10:00 am</td>
                   <td>Vasodhara Maha Purnahuti</td>
                 </tr>
                <tr>
                  <td>10:30 am</td>
                  <td>Sri Rudra Kalasha Abhishekam</td>
                 </tr>
                <tr>
                  <td>12:30 pm</td>
                   <td>Maha Aarati and Pradasdam</td>
                 </tr>
   
               </tbody>
             </Table>

          </Col>

          <Col>
          <Table bordered striped size="sm" style={{width: '98%', textAlign: 'left', marginLeft: 'auto', marginRight: 'auto'}}>
  <thead>
    <tr>
      <th colspan="4" style={{ textAlign: 'center', backgroundColor: '#FFBD33' }}>Sponsorship Oppurtunities</th>
      
    </tr>
    <tr style={{ backgroundColor: '#facf82'} }>
      <th></th>
      <th>Details</th>
      <th>Amount</th>
    </tr>
  </thead>
  <tbody>
   
   
    <tr>
      <td>Grand Patron</td>
      <td>Grand Sponsorship for the entire Sri Maha Rudra Yajnam</td>
      <td>$10,001 or above</td>
    </tr>
    <tr >
      <td>Ghanam Patron</td>
      <td>Sponsorship for one Ghanam</td>
      <td>$5001 or above</td>
    </tr>
    <tr>
      <td>2 day sponsor</td>
      <td>Sponsorship for Mahrarudra yagnam</td>
      <td>$3001</td>
    </tr>
    <tr>
      <td>One Priest sambavam</td>
      <td>( 3 priest)</td>
      <td>$1501</td>
    </tr>
    <tr>
      <td>Kalasam sponsor</td>
      <td>Sponsorship for one Kalasam</td>
      <td>$101</td>
    </tr>
    <tr>
      <td>Annadhanam Sponsor per Day</td>
      <td>( per day need around $5000)</td>
      <td>$1001</td>
    </tr>
    <tr>
      <td>Flowers </td>
      <td>( per day )</td>
      <td>$501</td>
    </tr>
    <tr>
      <td>Fruits </td>
      <td>(per day)</td>
      <td>$501</td>
    </tr>
    <tr>
      <td>Deepa Pooja</td>
      <td>Suggested donation by each participating Suvasini</td>
      <td>$101</td>
    </tr>
   
  </tbody>
</Table>

          </Col>

        </Row>

        

<hr></hr>
       
  </Container>
      </div>
      </div>

      );
    }
}


//
// <Card style={{display: 'inline' ,justifyContent: 'center'}}>
//            <Card.Body>
//            <Image  src="/assets/flyers/maharudram2022.png"  style= {{flex: 1,width: "60%",height: "60%",resizeMode: 'contain',}} ></Image>
//            </Card.Body>

//          </Card>
