import React from "react";
import {Container,Button} from "react-bootstrap";
import PriestServiceRegistration from './PriestServiceRegistration';
import  PayPalBtn  from "./PayPalBtn";



import "../App.css";


export default class Header extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            showHeaderPriestServiceRegistration: false 
        };
        this.handleHeaderPriestServiceRegistration = this.handleHeaderPriestServiceRegistration.bind(this)
    }
    
    paymentHandler = (details, data) => {
        /** Here you can call your backend API
          endpoint and update the database */
          alert("Transaction completed by " + details.payer.name.given_name);
          console.log(details, data);
          // OPTIONAL: Call your server to save the transaction
          return fetch("/paypal-transaction-complete", {
            method: "post",
            body: JSON.stringify({
              orderID: data.orderID
            })
          });
        
      }

    handleHeaderPriestServiceRegistration(){
        //this.setState({showHeaderPriestServiceRegistration: false})
        return(
            <div>
               <PriestServiceRegistration show={true}/>
               
          </div>
         
        );
       
      }

    render()
    {
        return(
            <div>
            <Container>
            <img src="/assets/icons/shree.png" style={{height:"5vh",width:"5vh"}} alt="shree"></img>
            <div class="round-corners-15px unround-bottom-corners" >
                <h2 style={{textAlign: 'center', color : 'red'}}> SRI RAJA RAJESWARI TEMPLE  </h2>
                    <h4>BHARATHIYA HINDU TEMPLE and CULTURAL CENTER</h4>
                    <h5> 2350 Paragon Drive San Jose, CA 95131</h5>
                    <h5> <img src="/assets/icons/phone.png" style={{height:"2vh",width:"2vh"}} alt="phone"></img> (510) 651 4148  <img src="/assets/icons/email.png" style={{height:"2vh",width:"2vh"}} alt="email"></img>priestoffremont@gmail.com</h5>
                    <h6 style={{color : 'red'}}> Tax Exemption ID # 80-0951248</h6>
             </div>
             </Container>
           
          <div style={{float: "left"}}>
            <img src="/assets/icons/btn_newtiming.png" style={{height:"5vh",width:"15vh"}} alt="timing"></img> 
          </div>
         
          <div style={{display: 'flex',  justifyContent: 'flex-end'}}>
          <Button variant="link" href="https://4ceb9cdb.sibforms.com/serve/MUIFADm4IMM95-p8aTzflx2tToBNl0YlKRbf4pWsa3fci72CYDQ5M8npKveboJxSY3MAKyi8BQ1fktDe8iAPnmFildi6gkrScjfnxWBBFTOpLoGQ-i14kY0NH_nXODbPLj7e7AxWTLbKk_l1XZT1gAuN1YkhzUYVDAzQunsiPcAYTsWrdiT5qaKYykBTQEnzciwiOYpOvA2v-A5v"> Join our mailing list </Button>
          <Button variant="link" href="https://chat.whatsapp.com/B5lv5Xp4Lq8ElsMfmlESvK">Join Temple Whatsapp group </Button>
          <Button variant="link" href="https://chat.whatsapp.com/CjlZzleXQnT1RZr7z83BKu"> Join Temple Volunteer group></Button>
             <Button variant="light" href="https://paybee.io/quickpay.html?handle=srrt#campaignList"><img src="/assets/icons/btn_donate.png" style={{height:"5vh",width:"15vh"}} alt="Donate"></img></Button>
          </div>
        
              {this.state.showHeaderPriestServiceRegistration ? this.handleHeaderPriestServiceRegistration() : null}
         </div>
        );
    }


}


/*
  <div style={{display: 'flex',  justifyContent: 'flex-end'}}>
             <PayPalBtn 
                 amount = {200}
                 currency = {'USD'}
                 onSuccess={this.paymentHandler}
             />
          </div>

*/
