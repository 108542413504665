import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

export default class Header extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            index: 0
        };
    }

     handleSelect = (selectedIndex, e) => {
        this.setState({index: selectedIndex});
      };

    render()
    {
        return(
            <div >
                 <Carousel activeIndex={this.state.index} onSelect={this.handleSelect}
                prevIcon={<span aria-hidden="true" style={{backgroundColor: "black"}} className="carousel-control-prev-icon" />}
                nextIcon={<span aria-hidden="true" style={{backgroundColor: "black"}} className="carousel-control-next-icon" />}
                indicators={true}
                controls={true}
               
                 >
                  <Carousel.Item  >
                  <Image
                   src="/assets/panchangeng/2024page1.png"
                   alt="2024 Panchang Main page"
                   thumbnail
                 />
                  </Carousel.Item>
                  <Carousel.Item>
                  <Image
                   src="/assets/panchangeng/2024Page2.png"
                  alt="2024 Panchang Second page"
                  thumbnail
                />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Page3.png"
                   alt="2024 Panchang About"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Jan.png"
                   alt="2024 January"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Feb.png"
                   alt="2024 February"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Mar.png"
                   alt="2024 March"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Apr.png"
                   alt="2024 April"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024May.png"
                   alt="2024 May"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Jun.png"
                   alt="2024 June"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Jul.png"
                   alt="2024 July"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Aug.png"
                   alt="2024 August"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Sep.png"
                   alt="2024 September"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Oct.png"
                   alt="2024 October"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Nov.png"
                   alt="2024 November"
                   thumbnail
                 />
                </Carousel.Item>
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024Dec.png"
                   alt="2024 December"
                   thumbnail
                 />
                </Carousel.Item>              
                <Carousel.Item>
                <Image
                   src="/assets/panchangeng/2024ImportantDates.png"
                   alt="2024 ImportantDates"
                   thumbnail
                 />
                </Carousel.Item>

    </Carousel>

            </div>
        )
    }

}