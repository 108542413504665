export default [
    {
      'title': 'Shatru Samhara Trishati Archana',
      'start': new Date('March 18 2021 19:00 PST'),
      'end':   new Date('March 18 2021 20:30 PST')
    },
    {
      'title': 'Pradosham',
      'start': new Date('March 25 2021 18:00 PST'),
      'end':  new Date('March 25 2021 19:30 PST')
    },
  
    {
      'title': 'Ayyapa Abishekam ( Last Saturday)',
      'start': new Date('March 27 2021 18:00 PST'),
      'end':  new Date('March 17 2021 19:30 PST')
    },
  
    {
      'title': 'Sankatahara Chathurthi',
      'start': new Date('March 31 2021 19:00 PST'),
      'end': new Date('March 31 2021 20:00 PST')
    },
    {
      'title': 'Sahtru Samhara Trishati',
      'start': new Date('April 17 2021 17:30 PST'),
      'end': new Date('April 17 2021 20:00 PST')
    },
    {
      'title': 'Sani Prodhosham - Ekadasa Vara Rudrabhishekam',
      'start': new Date('April 24 2021 15:00 PST'),
      'end': new Date('April 24 2021 20:00 PST')
    },
    {
      'title': 'Sankatahara Chathurthi',
      'start': new Date('April 29 2021 19:00 PST'),
      'end': new Date('April 29 2021 20:00 PST')
    },
    {
      'title': 'Sankatahara Chathurthi',
      'start': new Date('May 28 2021 19:00 PST'),
      'end': new Date('May 28 2021 20:00 PST')
    },
    {
      'title': 'Sahtru Samhara Trishati',
      'start': new Date('May 17 2021 17:30 PST'),
      'end': new Date('May 17 2021 20:00 PST')
    },
    {
      'title': 'Ayyapa Abishekam ( 4th Saturday)',
      'start': new Date('May 22 2021 18:00 PST'),
      'end':  new Date('May 22 2021 19:30 PST')
    },
    {
      'title': 'Pradosham ( Mahanyasam & Ekadasha Rudrabhishekam)',
      'start': new Date('May 8 2021 15:00 PST'),
      'end':  new Date('March 17 2021 19:30 PST')
    },
    {
      'title': 'Pradosham ( Mahanyasam & Ekadasha Rudrabhishekam)',
      'start': new Date('May 23 2021 15:00 PST'),
      'end':  new Date('May 23 2021 17:30 PST')
    },
    {
      'title': 'Sankara Jayanthi (Avahanthi Homam)',
      'start': new Date('May 16 2021 16:00 PST'),
      'end':  new Date('May 16 2021 17:30 PST')
    },
    {
      'title': 'Vaigasi Vishakam(Subramaniar Abhishekam)',
      'start': new Date('May 25 2021 18:30 PST'),
      'end':  new Date('May 25 2021 19:30 PST')
    },
    {
      'title': 'Sri Raja Rajeswari Abhishekam',
      'start': new Date('May 2 2021 17:00 PST'),
      'end':  new Date('May 2 2021 19:30 PST')
    },
    {
      'title': 'Sri Raja Rajeswari Abhishekam',
      'start': new Date('May 9 2021 17:00 PST'),
      'end':  new Date('May 9 2021 19:30 PST')
    },
    {
      'title': 'Sri Raja Rajeswari Abhishekam',
      'start': new Date('May 16 2021 17:00 PST'),
      'end':  new Date('May 16 2021 19:30 PST')
    },
    {
      'title': 'Sri Raja Rajeswari Abhishekam',
      'start': new Date('May 23 2021 17:00 PST'),
      'end':  new Date('May 23 2021 19:30 PST')
    },
    {
      'title': 'Sri Raja Rajeswari Abhishekam',
      'start': new Date('May 30 2021 17:00 PST'),
      'end':  new Date('May 30 2021 19:30 PST')
    }
  ]
  