export const KumbhabhishekamPhotos = [
    {
        src: '/assets/kumbhabhishekam/K1.jpeg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/kumbhabhishekam/K2.jpeg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/kumbhabhishekam/K3.jpeg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/kumbhabhishekam/K4.jpeg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/kumbhabhishekam/K5.jpeg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/kumbhabhishekam/K6.jpeg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/kumbhabhishekam/K7.jpeg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/kumbhabhishekam/K8.jpeg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/kumbhabhishekam/K9.jpeg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/kumbhabhishekam/K10.jpeg',
        width: 1,
        height: 1
      }

];

export const MahaRudramPhotos = [
     {
        src: '/assets/maharudram/MH16_1.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH16_2.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH16_3.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH16_4.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH16_5.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH16_6.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH16_7.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH16_8.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH16_9.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/DP1.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/DP2.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/DP3.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/DP4.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/DP5.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/DP6.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/DP7.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH17_1.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH17_2.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH17_3.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH17_4.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH17_5.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH17_6.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH17_7.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH17_8.jpg',
        width: 1,
        height: 1
      },
      {
        src: '/assets/maharudram/MH17_9.jpg',
        width: 1,
        height: 1
      },
    ]


