import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default class DonatePage extends React.Component {

  render() {
   return(
    <div>
      <Button variant="light" href="https://paybee.io/quickpay.html?handle=srrt#campaignList"><img src="/assets/icons/btn_donate.png" style={{height:"5vh",width:"15vh"}} alt="Donate"></img> Pay Bee </Button>
    </div>
    );
  }

}
