import React from 'react';
import Header from "./Header";
import {Container,Button,Row,Col} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import {useNavigate} from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

export default class PoojaItemList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        validated: false,
        show: true,
        index: props.poojaIndex
    };
    this.handleClosePoojaList = this.handleClosePoojaList.bind(this);
   }

   changeState() {
     console.log(" In change State");
     this.props.handlePLState();
   };

   handleClosePoojaList(event) {
    console.debug(" in handle ClosePooja List");
    this.setState({validated : true});
    this.setState({show: false});
    this.changeState();
  };

    render() {

      console.log(this.state.index)
      var whatToCall = this.modalboxGH()
      switch (this.state.index ) {
        case 1: 
               whatToCall = this.modalboxGH();
               break;
        case 2: 
               whatToCall = this.modalboxHS();
               break;
        default:
            whatToCall = this.modalboxGH()
           
      }
      return (
        <>
          <Modal
            show={this.state.show}
            onHide={this.handleClosePoojaList}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                 Pooja Item List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {whatToCall}
            </Modal.Body>
          </Modal>
        </>
      );
    }

    modalboxGH()
    {
        return (
        <div>
          <Table bordered striped size="sm" style={{width: '98%', textAlign: 'left', marginLeft: 'auto', marginRight: 'auto'}}>
            <thead>
               <tr>
                  <th colspan="4" style={{ textAlign: 'center', backgroundColor: '#FFBD33' }}>Ganapathy homam</th>
                </tr>
                 <tr style={{ backgroundColor: '#facf82'} }>
                 <th>Item</th>
                <th>Quantity</th>
              </tr>
           </thead>
         <tbody> 
         <tr>
          <td>Turmeric powder</td>
          <td>25 grms</td>
         </tr>
         <tr>
          <td>Kumkum</td>
          <td>25 grms</td>
         </tr>
         <tr>
          <td>Beetel leaves</td>
          <td>25 nos</td>
         </tr>
         <tr>
          <td>Beetel Nuts</td>
          <td>30 nos</td>
         </tr>
         <tr>
          <td>Agarbathi</td>
          <td>1 box</td>
         </tr>
         <tr>
          <td>Camphor</td>
          <td>25 grms</td>
         </tr>
         <tr>
          <td>Sandal powder</td>
          <td>1 box</td>
         </tr>
         <tr>
          <td>Rice </td>
          <td>5 lbs</td>
         </tr>
         <tr>
          <td>Paper Towels</td>
          <td>2 (full size)</td>
         </tr>
         <tr>
          <td>Blouse piece</td>
          <td>1</td>
         </tr>
         <tr>
          <td>Flowers</td>
          <td>2 bunches</td>
         </tr>
         <tr>
          <td>Flower garland</td>
          <td>1</td>
         </tr>
         <tr>
          <td>Coconut</td>
          <td>3</td>
         </tr>
         <tr>
          <td>Banana</td>
          <td>2 dozen</td>
         </tr>
         <tr>
          <td>Quarter coins</td>
          <td>40</td>
         </tr>
         <tr>
          <td>Yogurt</td>
          <td>1 quart</td>
         </tr>
         <tr>
          <td>Ghee</td>
          <td>1 lb</td>
         </tr>
         <tr>
          <td>Dry coconut (whole)</td>
          <td>3</td>
         </tr>
         <tr>
          <td>Cardamom</td>
          <td>10 gm</td>
         </tr>
         <tr>
          <td>Pure silk cloth</td>
          <td>1 mt</td>
         </tr>
         <tr>
          <td>Modakam</td>
          <td>21 or 16</td>
         </tr>
   
  </tbody>
</Table>
        
        </div>
        );

    }

    modalboxHS()
    {
        return (
          <div>
          <Table bordered striped size="sm" style={{width: '98%', textAlign: 'left', marginLeft: 'auto', marginRight: 'auto'}}>
            <thead>
               <tr>
                  <th colspan="4" style={{ textAlign: 'center', backgroundColor: '#FFBD33' }}>Hiranya Shardham</th>
                </tr>
                 <tr style={{ backgroundColor: '#facf82'} }>
                 <th>Item</th>
                <th>Quantity</th>
              </tr>
           </thead>
         <tbody> 
         <tr>
          <td>Rice</td>
          <td>2 lb</td>
         </tr>
         <tr>
          <td>Vegetable (raw)</td>
          <td>1 lb</td>
         </tr>
         <tr>
          <td>Moong Dhal</td>
          <td>1 lb</td>
         </tr>
         <tr>
          <td>jaggery</td>
          <td>1 lb</td>
         </tr>
         <tr>
          <td>Beetel leaves</td>
          <td>10</td>
         </tr>
         <tr>
          <td>Beetel Nuts</td>
          <td>10</td>
         </tr>
         <tr>
          <td>Banana</td>
          <td>10</td>
         </tr>
         <tr>
          <td>Quarter coins</td>
          <td>20</td>
         </tr>
   
  </tbody>
</Table>
        
        </div>
        );

    }
     
      
}